import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import RichTextEditor from "../../components/RichTextEditor";

import { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useCreateJobMutation,
  useEditJobMutation,
  useGetJobDataMutation,
  useListLocationsMutation,
  useListTeamsMutation,
  useListWorkTypesMutation,
} from "../../store/services/jobs";

let initialValues = {
  car_title: "",
  car_team: 0,
  car_link: "",
  car_role: 0,
  car_slug: "",
  car_descr: "",
  car_featured: "",
  car_keywords: "",
  car_category: 0,
  car_skill: "",
  car_date: new Date(),
  car_location: 0,
  car_country: "india",
  status: "0",
};

const countrysList = [
  { label: "India", value: "india" },
  { label: "United States", value: "unitedStates" },
  { label: "Global", value: "global" },
];

const CreateJobs = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [createJobs, { isSuccess: createJobSuccess, isError: createJobError }] = useCreateJobMutation();

  const [getWorkTypes, { data: workTypesList, error: workTypesListError, isSuccess: isWorkTypesListFetchSuccess }] = useListWorkTypesMutation();
  const [getTeams, { data: teamsList, error: teamsListError, isSuccess: isTeamsFetchSuccess }] = useListTeamsMutation();
  const [getLocations, { data: locationsList, error: locationListError, isSuccess: isLocationFetchSuccess }] = useListLocationsMutation();

  const [selectedWorkType, setSelectedWorkTypes] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("India");

  useEffect(() => {
    if (createJobSuccess) {
      toast({
        title: "Job Created Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/jobs");
    }
    if (createJobError) {
      toast({
        title: "Error Creating Job",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [createJobSuccess, createJobError]);

  useEffect(() => {
    getWorkTypes();
    getTeams();
    getLocations();
  }, []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      // setFieldValue("page_keywords", JSON.stringify(values.page_keywords));

      if (
        values.car_title == "" ||
        values.car_descr == "" ||
        values.car_skill == "" ||
        selectedTeam == "" ||
        selectedWorkType == "" ||
        selectedLocation == "" ||
        selectedCountry == ""
      ) {
        alert("Please fill all the fields");
        return;
      }
      let countryVal;
      if (selectedCountry === "United States") {
        countryVal = "unitedStates";
      } else {
        countryVal = selectedCountry.toLowerCase();
      }

      console.log("car_Country = ", selectedCountry);
      let finalObj = {
        ...values,
        car_team: selectedTeam,
        car_role: selectedWorkType,
        car_role: selectedWorkType,
        car_location: selectedLocation,
        car_country: countryVal,
        car_featured: null,
        car_keywords: null,
        car_date: new Date(),
        status: "0",
        car_slug: values.car_title.split(" ").join("-"),
      };
      console.log("Final Obj =", finalObj);
      createJobs(finalObj);
      action.resetForm();
    },
  });

  return (
    <div className="p-5 pt-10">
      <div className="flex justify-between mb-8">
        <h1 className="text-3xl font-bold">Create Job</h1>
        {/* <button className="bg-red-500 px-5 py-1 text-white rounded-md">Delete</button> */}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          <label className="mr-4 block text-lg font-semibold" htmlFor="title">
            Job Title
          </label>
          <input value={values.car_title} onChange={handleChange} className="w-full rounded mt-2 border h-12 px-4" type="text" name="car_title" id="car_title" />
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold">Country</label>
          <select
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            className="bg-gray-200 text-black py-2 px-4 rounded-md focus:outline-none w-full mt-2"
          >
            <option value="" disabled>
              Select an option
            </option>
            {countrysList &&
              countrysList?.length > 0 &&
              countrysList.map((option) => (
                <option key={option?.value} value={option?.value}>
                  {option?.label}
                </option>
              ))}
          </select>
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold">Location</label>
          <select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            className="bg-gray-200 text-black py-2 px-4 rounded-md focus:outline-none w-full mt-2"
          >
            <option value="" disabled>
              Select an option
            </option>
            {locationsList &&
              locationsList?.length > 0 &&
              locationsList.map((option) => (
                <option key={option?.c_l_id} value={option?.c_l_id}>
                  {option?.c_l_title}
                </option>
              ))}
          </select>
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold">Team</label>
          <select
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
            className="bg-gray-200 text-black py-2 px-4 rounded-md focus:outline-none w-full mt-2"
          >
            <option value="" disabled>
              Select an option
            </option>
            {teamsList &&
              teamsList?.length > 0 &&
              teamsList.map((option) => (
                <option key={option?.c_t_id} value={option?.c_t_id}>
                  {option?.c_t_title}
                </option>
              ))}
          </select>
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold">Work Types</label>
          <select
            value={selectedWorkType}
            onChange={(e) => setSelectedWorkTypes(e.target.value)}
            className="bg-gray-200 text-black py-2 px-4 rounded-md focus:outline-none w-full mt-2"
          >
            <option value="" disabled>
              Select an option
            </option>
            {workTypesList &&
              workTypesList?.length > 0 &&
              workTypesList.map((option) => (
                <option key={option?.c_w_id} value={option?.c_w_id}>
                  {option?.c_w_title}
                </option>
              ))}
          </select>
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold" htmlFor="car_link">
            LinkedIn Link
          </label>
          <input
            value={values.car_link}
            onChange={handleChange}
            className="w-full rounded mt-2 border h-12 px-4"
            type="text"
            name="car_link"
            id="car_link"
            placeholder="LinkedIn Link (please leave it blank for built-in form)"
          />
        </div>
        <div className="mt-8 ">
          <label className="mr-4 block text-lg font-semibold" htmlFor="car_descr">
            Description
          </label>
          {/* <input id="x" type="hidden" name="content" /> */}
          <RichTextEditor
            value={values.car_descr}
            setValue={(val) => {
              // setDescription(val);
              setFieldValue("car_descr", val);
            }}
          />
        </div>

        <div className="mt-8 mb-10">
          <label className="mr-4 block text-lg font-semibold" htmlFor="car_skill">
            Skill Requirements
          </label>
          {/* <input id="x" type="hidden" name="content" /> */}
          <RichTextEditor
            value={values.car_skill}
            setValue={(val) => {
              // setDescription(val);
              setFieldValue("car_skill", val);
            }}
          />
        </div>

        <div className="flex justify-center">
          <button className="bg-black text-white px-4 py-2 rounded-md mb-10 " type="submit">
            Create Job
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateJobs;
