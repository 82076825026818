import { FaCirclePlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { useToast } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import Modal from "../Modal";
import { useEffect, useState } from "react";
import { useDeleteJobMutation } from "../../store/services/jobs";

const JobCard = ({ job, getJobsList }) => {
  const [deleteJob, { isSuccess: deleteJobSuccess, isError: deleteJobError, data: deleteJobData }] = useDeleteJobMutation();

  const toast = useToast();
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    if (deleteJobSuccess) {
      toast({
        title: "Job Deleted Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      getJobsList();
      setDeleteModal(false);
    }
    if (deleteJobError) {
      toast({
        title: "Error Deleting Job",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setDeleteModal(false);
    }
  }, [deleteJobSuccess, deleteJobError]);

  return (
    <div className="flex flex-col bg-blue-200  rounded-md min-h-[150px] justify-between">
      <div className="  p-2 font-medium text-lg">{job?.car_title}</div>
      <div className="p-2 flex gap-1 items-center font-normal  border-t-2 border-black">
        <div className="flex gap-4">
          <div onClick={() => navigate(`/jobs/${encodeURIComponent(job?.car_id)}`)} className="flex items-center text-lg gap-1 cursor-pointer">
            <span>
              <MdEdit />
            </span>
            Edit
          </div>
          <div
            onClick={() => {
              setDeleteModal(true);
              setDeleteId(job?.car_id);
            }}
            className="flex items-center text-lg gap-1 cursor-pointer"
          >
            <span>
              <MdDelete />
            </span>
            Delete
          </div>
        </div>
      </div>
      {deleteModal && (
        <Modal
          onClose={() => {
            setDeleteModal(false);
            setDeleteId(null);
          }}
        >
          <div>
            <h4 className="text-lg font-medium mb-3 text-center">Are you sure you want to delete?</h4>
            <div className="flex gap-4">
              <button onClick={() => deleteJob(deleteId)} className="bg-black text-white rounded-lg px-4 py-2 w-full">
                Yes
              </button>
              <button
                onClick={() => {
                  setDeleteModal(false);
                  setDeleteId(null);
                }}
                className="border border-black rounded-lg px-4 py-2 w-full"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default JobCard;
