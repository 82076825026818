import { useEffect, useState } from "react";
import moment from "moment";
import "./careerApp.css";
import { useGetApplicantsMutation, useChangeApplicationStatusMutation } from "../../store/services/applicants";
import { useGetPositionsMutation } from "../../store/services/positionsApi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ApplicationAccordion from "../../components/ApplicantsAccordion";
import CustomDropdown from "../../components/CustomSelect";
import PaginationButtons from "../../components/Pagination";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Stats = () => {
  //importing api mutation to call api and access data

  const [filter, setFilter] = useState({
    // status: "",
    role: "",
    pageSize: 25,
    page: 0,
    search: "",
  });

  const { position } = useParams();
  const navigate = useNavigate();

  const [getApplicants, { data: applicantsData, error: applicantsError, isError: isApplicantFetchError, isSuccess: isApplicantFetchSuccess }] =
    useGetApplicantsMutation();

  useEffect(() => {
    console.log("get ");
    filter["role"] = position;
    getApplicants(filter);
    window.scrollTo(0, 0);
  }, [filter]);

  return (
    <main className=" w-full bg-white flex-col flex p-10  ">
      <div className="filters w-full relative my-5">
        <h1 className="text-2xl font-bold">
          {" "}
          <span className="text-xl mr-4 font-bold inline-block cursor-pointer" onClick={() => navigate(-1)}>
            {" "}
            🔙{" "}
          </span>{" "}
          Applicants
        </h1>

        <div className="grid gap-5 mt-10 grid-cols-4 ">
          <div onClick={() => navigate("list0")} className="applied p-5 bg-blue-200 cursor-pointer rounded-md flex justify-between">
            {" "}
            <span> Applied </span> <span>{applicantsData?.list0Count}</span>
          </div>
          <div onClick={() => navigate("list7")} className="applied p-5 bg-blue-200 cursor-pointer rounded-md flex justify-between">
            {" "}
            <span> Shelf </span> <span>{applicantsData?.list7Count}</span>
          </div>
          <div onClick={() => navigate("list6")} className="applied p-5 bg-blue-200 cursor-pointer rounded-md flex justify-between">
            {" "}
            <span> Shortlist </span> <span>{applicantsData?.list6Count}</span>
          </div>
          <div onClick={() => navigate("list1")} className="applied p-5 bg-blue-200 cursor-pointer rounded-md flex justify-between">
            {" "}
            <span> Interview/Tasks </span> <span>{applicantsData?.list1Count}</span>
          </div>
          <div onClick={() => navigate("list2")} className="applied p-5 bg-blue-200 cursor-pointer rounded-md flex justify-between">
            {" "}
            <span> Offered </span> <span>{applicantsData?.list2Count}</span>
          </div>
          <div onClick={() => navigate("list3")} className="applied p-5 bg-blue-200 cursor-pointer rounded-md flex justify-between">
            {" "}
            <span> Accepted </span> <span>{applicantsData?.list3Count}</span>
          </div>
          <div onClick={() => navigate("list4")} className="applied p-5 bg-blue-200 cursor-pointer rounded-md flex justify-between">
            {" "}
            <span> Rejected </span> <span>{applicantsData?.list4Count}</span>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Stats;
