import { Fragment, useEffect, useState } from "react";
import { useDeleteTeamMutation } from "../../store/services/jobs";

import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";

import { useToast } from "@chakra-ui/react";
import Modal from "../Modal";

const TeamCard = ({ t, setTeamEdit, setTeamVal, handleGetTeams }) => {
  const [deleteTeam, { isSuccess: deleteTeamSuccess, isError: deleteTeamError, data: deleteTeamData }] = useDeleteTeamMutation();

  const toast = useToast();

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    if (deleteTeamSuccess) {
      toast({
        title: "Team Deleted Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      handleGetTeams();
      setDeleteModal(false);
    }
    if (deleteTeamError) {
      toast({
        title: "Error Deleting Team",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setDeleteModal(false);
    }
  }, [deleteTeamSuccess, deleteTeamError]);
  return (
    <>
      <div className="flex justify-between items-center bg-gray-200 px-2 py-1 ">
        <span>{t?.c_t_title}</span>
        <span className="flex gap-2 items-center">
          <span
            onClick={() => {
              setTeamVal(t?.c_t_title);
              setTeamEdit(t);
            }}
            className="cursor-pointer "
          >
            <MdEdit />
          </span>
          <span
            onClick={() => {
              setDeleteModal(true);
              setDeleteId(t?.c_t_id);
            }}
            className="cursor-pointer"
          >
            <MdDelete />
          </span>
        </span>
      </div>
      {deleteModal && (
        <Modal
          onClose={() => {
            setDeleteModal(false);
            setDeleteId(null);
          }}
        >
          <div>
            <h4 className="text-lg font-medium mb-3 text-center">Are you sure you want to delete?</h4>
            <div className="flex gap-4">
              <button onClick={() => deleteTeam(deleteId)} className="bg-black text-white rounded-lg px-4 py-2 w-full">
                Yes
              </button>
              <button
                onClick={() => {
                  setDeleteModal(false);
                  setDeleteId(null);
                }}
                className="border border-black rounded-lg px-4 py-2 w-full"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TeamCard;
