import React, { useState, useEffect } from "react";
import LoginPage from "./pages/auth/Login";
// import Dashboard from "./pages/dashboard/Dashboard";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import CareerApplicants from "./pages/careerApplicants/CareerApplicants";

import CreateUser from "./pages/createUser/CreateUser";
import Blogs from "./pages/blogs/Blogs";
import CreateBlogs from "./pages/blogs/CreateBlogs";
import CareerLists from "./pages/careerList/careerList";
import Sidebar from "./components/Sidebar";
import Stats from "./pages/careerApplicants/stats";
import { useAppSelector } from "./store/hooks";

import EditBlogs from "./pages/blogs/EditBlogs";
import Jobs from "./pages/jobs/Jobs";
import EditJobs from "./pages/jobs/EditJobs";
import CreateJobs from "./pages/jobs/CreateJobs";
import Registrations from "./pages/hiveUS/Registerations";
function App() {
  // check if user is logged in or not
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  // const isAuthenticated = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user") || "") : false;

  return (
    <div className={`w-full bg-gray-100  `}>
      <Header isAuthenticated={isAuthenticated} />

      <div className="mt-16">
        {!isAuthenticated && (
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/*" element={<LoginPage />} />
          </Routes>
        )}
        {isAuthenticated && (
          <div className="flex">
            <div className="w-1/6">
              <Sidebar />
            </div>
            <div className="w-5/6  ">
              <Routes>
                {isAuthenticated && (
                  <>
                    <Route path="/career" element={<CareerLists />} />
                    <Route path="/career/:position" element={<Stats />} />
                    <Route path="/career/:position/:status" element={<CareerApplicants />} />
                    <Route path="/CreateUser" element={<CreateUser />} />
                    <Route path="/Blogs" element={<Blogs />} />
                    <Route path="/blogs/:id" element={<EditBlogs />} />
                    <Route path="/createBlogs" element={<CreateBlogs />} />
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/createJobs" element={<CreateJobs />} />
                    <Route path="/jobs/:id" element={<EditJobs />} />
                    <Route path="/usRegistrations" element={<Registrations />} />
                    <Route path="/*" element={<CareerLists />} />
                  </>
                )}
              </Routes>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
