import { Fragment, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import {
  useCreateJobMutation,
  useCreateLocationMutation,
  useCreateTeamMutation,
  useCreateWorkMutation,
  useEditJobMutation,
  useEditLocationMutation,
  useEditTeamMutation,
  useEditWorkMutation,
  useGetJobsMutation,
  useListLocationsMutation,
  useListTeamsMutation,
  useListWorkTypesMutation,
} from "../../store/services/jobs";
import JobCard from "../../components/jobs/JobCard";
import TeamCard from "../../components/jobs/TeamCard";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import axios from "axios";

import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { FaChevronCircleRight } from "react-icons/fa";
import LocationCard from "../../components/jobs/LocationCard";
import WorkCard from "../../components/jobs/WorkCard";

const Jobs = () => {
  const [getJobs, { data: jobsData, error: jobsError, isError: isJobsFetchError, isSuccess: isJobsFetchSuccess }] = useGetJobsMutation();

  const [getWorkTypes, { data: workTypesList, error: workTypesListError, isSuccess: isWorkTypesListFetchSuccess }] = useListWorkTypesMutation();
  const [createWork, { isSuccess: createWorkSuccess, isError: createWorkError }] = useCreateWorkMutation();
  const [editWork, { isSuccess: editWorkSuccess, isError: editWorkError }] = useEditWorkMutation();

  const [getLocations, { data: locationsList, error: locationListError, isSuccess: isLocationFetchSuccess }] = useListLocationsMutation();
  const [createLocation, { isSuccess: createLocationSuccess, isError: createLocationError }] = useCreateLocationMutation();
  const [editLocation, { isSuccess: editLocationSuccess, isError: editLocationError }] = useEditLocationMutation();

  const [getTeams, { data: teamsList, error: teamsListError, isSuccess: isTeamsFetchSuccess }] = useListTeamsMutation();
  const [createTeam, { isSuccess: createTeamSuccess, isError: createTeamError }] = useCreateTeamMutation();
  const [editTeam, { isSuccess: editTeamSuccess, isError: editTeamError }] = useEditTeamMutation();

  const [teamsModal, setShowTeamsModal] = useState(false);
  const [teamVal, setTeamVal] = useState("");
  const [teamEdit, setTeamEdit] = useState(null);

  const [locationModal, setShowLocationModal] = useState(false);
  const [locationVal, setLocationVal] = useState("");
  const [locationEdit, setLocationEdit] = useState(null);

  const [workModal, setShowWorkModal] = useState(false);
  const [workVal, setWorkVal] = useState("");
  const [workEdit, setWorkEdit] = useState(null);

  const [rebuildWebsite, setRebuildWebsite] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    getJobs();
    getWorkTypes();
    getTeams();
    getLocations();
  }, []);

  useEffect(() => {
    if (createTeamSuccess) {
      toast({
        title: "Team Created Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTeamVal("");
      getTeams();
      // navigate("/jobs");
    }
    if (createTeamError) {
      toast({
        title: "Error Creating Team",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [createTeamSuccess, createTeamError]);

  useEffect(() => {
    if (editTeamSuccess) {
      toast({
        title: "Team Updated Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTeamVal("");
      getTeams();
      setTeamEdit(null);
      // navigate("/jobs");
    }
    console.log("EDIT Team Error =", editTeamError);
    if (editTeamError) {
      toast({
        title: "Error Updating Team",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [editTeamSuccess, editTeamError]);

  useEffect(() => {
    if (createLocationSuccess) {
      toast({
        title: "Location Created Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLocationVal("");
      getLocations();
      // navigate("/jobs");
    }
    if (createLocationError) {
      toast({
        title: "Error Creating Location",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [createLocationSuccess, createLocationError]);

  useEffect(() => {
    if (editLocationSuccess) {
      toast({
        title: "Location Updated Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLocationVal("");
      getLocations();
      setLocationEdit(null);
      // navigate("/jobs");
    }

    if (editLocationError) {
      toast({
        title: "Error Updating Location",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [editLocationSuccess, editLocationError]);

  useEffect(() => {
    if (createWorkSuccess) {
      toast({
        title: "Work Created Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setWorkVal("");
      getWorkTypes();
      // navigate("/jobs");
    }
    if (createWorkError) {
      toast({
        title: "Error Creating Work",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [createWorkSuccess, createWorkError]);

  useEffect(() => {
    if (editWorkSuccess) {
      toast({
        title: "Work Updated Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setWorkVal("");
      getWorkTypes();
      setWorkEdit(null);
      // navigate("/jobs");
    }

    if (editWorkError) {
      toast({
        title: "Error Updating Work",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [editWorkSuccess, editWorkError]);

  const getJobsList = () => {
    getJobs();
  };

  const onRebuildWebsite = async () => {
    try {
      const response = await axios.post("https://webhook.praan.io/start-build");
      console.log(response);
      if (response.status === 200) {
        alert("Rebuild Website Started");
        setRebuildWebsite(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateTeam = () => {
    if (teamVal === "") {
      toast({
        title: "Team Name is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    let body = {
      c_t_title: teamVal,
      status: "0",
    };

    createTeam(body);
  };

  const handleCreateLocation = () => {
    if (locationVal === "") {
      toast({
        title: "Location Name is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    let body = {
      c_l_title: locationVal,
      status: "0",
    };

    createLocation(body);
  };

  const handleCreateWork = () => {
    if (workVal === "") {
      toast({
        title: "Work Name is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    let body = {
      c_w_title: workVal,
      status: "0",
    };

    createWork(body);
  };

  const handleEditTeam = () => {
    if (teamVal === "") {
      toast({
        title: "Team Name is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    console.log("editTeam ===", teamEdit);

    let body = {
      c_t_id: teamEdit?.c_t_id,
      c_t_title: teamVal,
      status: "0",
    };
    console.log("BODY ===", body);
    editTeam(body);
  };

  const handleEditLocation = () => {
    if (locationVal === "") {
      toast({
        title: "Location Name is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    console.log("editTeam ===", teamEdit);

    let body = {
      c_l_id: locationEdit?.c_l_id,
      c_l_title: locationVal,
      status: "0",
    };
    console.log("BODY ===", body);
    editLocation(body);
  };

  const handleEditWork = () => {
    if (workVal === "") {
      toast({
        title: "WorkType Name is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    let body = {
      c_w_id: workEdit?.c_w_id,
      c_w_title: workVal,
      status: "0",
    };

    editWork(body);
  };

  const handleGetTeams = () => {
    getTeams();
  };

  const handleGetLocations = () => {
    getLocations();
  };

  const handleGetWorks = () => {
    getWorkTypes();
  };

  return (
    <Fragment>
      {rebuildWebsite && (
        <div className="fixed top-0 left-0 h-screen bg-black bg-opacity-10 w-screen flex justify-center items-center">
          <div className="w-1/2 h-1/2 bg-white p-5 flex flex-col items-center justify-evenly">
            <h1 className="text-2xl font-bold">Rebuilding Website</h1>
            <p className="text-center">Rebuiling website will cause the website to remain down for 1-2 mins, please continue only if you are sure what you are doing</p>
            <div className="flex gap-5 ">
              <button onClick={onRebuildWebsite} className="px-6 py-1 bg-red-500 rounded-md text-white text-lg uppercase">
                Rebuild
              </button>
              <button onClick={() => setRebuildWebsite(false)} className="px-6 py-1 bg-black rounded-md text-white text-lg uppercase">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mx-2 my-4">
        <div className="flex justify-between mb-5 mx-2">
          <h1 className="text-2xl font-bold mb-2">Jobs</h1>
          <div className="flex items-center gap-2">
            <div>
              <button onClick={() => setRebuildWebsite(true)} className="px-6 py-1 bg-red-500 rounded-md text-white text-lg uppercase">
                Rebuild Website
              </button>
            </div>
            <div onClick={() => navigate(`/createJobs`)} className="bg-black p-2 w-fit text-white flex items-center gap-2 rounded-lg cursor-pointer">
              {/* <span>
              <FaPlus />
            </span> */}
              Add Job Post
            </div>
          </div>
          {/* </div> */}
        </div>

        {/* Filter Options */}
        <div className="bg-gray-200 px-2 py-2 rounded-lg mb-10">
          <h2 className="text-lg font-semibold border-b-2 border-black mb-4 pb-2">Filter Options</h2>
          <div className="grid grid-cols-3 gap-2">
            <div className="flex flex-col bg-blue-200  rounded-lg">
              <div className=" border-b-2 border-black p-2 font-medium text-lg">Team</div>
              <div onClick={() => setShowTeamsModal(true)} className="p-2 flex gap-1 items-center font-normal cursor-pointer">
                <span>
                  <FaCirclePlus />
                </span>
                <span>Add</span>
                <span>/</span>
                <span>Edit</span>
              </div>
            </div>

            <div className="flex flex-col bg-blue-200  rounded-lg">
              <div className=" border-b-2 border-black p-2 font-medium text-lg">Location</div>
              <div onClick={() => setShowLocationModal(true)} className="p-2 flex gap-1 items-center font-normal cursor-pointer">
                <span>
                  <FaCirclePlus />
                </span>
                <span>Add</span>
                <span>/</span>
                <span>Edit</span>
              </div>
            </div>

            <div className="flex flex-col bg-blue-200  rounded-lg">
              <div className=" border-b-2 border-black p-2 font-medium text-lg">Work Type</div>
              <div onClick={() => setShowWorkModal(true)} className="p-2 flex gap-1 items-center font-normal cursor-pointer">
                <span>
                  <FaCirclePlus />
                </span>
                <span>Add</span>
                <span>/</span>
                <span>Edit</span>
              </div>
            </div>
          </div>
        </div>

        {/* Jobs List  */}
        <div className="bg-gray-200 px-2 py-2 rounded-lg grid grid-cols-3 gap-4 h-[550px] overflow-y-auto">
          {jobsData &&
            jobsData?.length > 0 &&
            jobsData.map((jd) => {
              return <JobCard getJobsList={getJobsList} key={jd?.car_id} job={jd} />;
            })}
        </div>
      </div>
      {teamsModal && (
        <Modal
          modalStyle="w-[50%] important"
          onClose={() => {
            setTeamEdit(null);
            setTeamVal("");
            setShowTeamsModal(false);
          }}
        >
          <div className="flex w-full gap-5">
            <div className="w-full border-r-2 border-gray-400 pr-7 ">
              <h2 className="text-lg font-semibold">Team Filter</h2>
              <div className="flex flex-col gap-1 h-72  overflow-y-auto scroll-smooth mt-2">
                {teamsList &&
                  teamsList?.length > 0 &&
                  teamsList.map((t) => <TeamCard key={t?.c_t_id} t={t} setTeamEdit={setTeamEdit} setTeamVal={setTeamVal} handleGetTeams={handleGetTeams} />)}
              </div>
            </div>
            <div className="w-full">
              <h2 className="text-lg font-semibold">
                <label className="mr-4 block text-lg font-semibold" htmlFor="title">
                  Title
                </label>
                {teamEdit !== null ? (
                  <>
                    <input value={teamVal} onChange={(e) => setTeamVal(e.target.value)} className="w-full rounded mt-2 border h-12 px-4" type="text" />
                    <div className="flex gap-4">
                      <button onClick={handleEditTeam} className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center">
                        Edit
                        <span>
                          <FaChevronCircleRight />
                        </span>
                      </button>
                      <button
                        onClick={() => {
                          setTeamEdit(null);
                          setTeamVal("");
                        }}
                        className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center"
                      >
                        cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <input value={teamVal} onChange={(e) => setTeamVal(e.target.value)} className="w-full rounded mt-2 border h-12 px-4" type="text" />
                    <button onClick={handleCreateTeam} className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center">
                      Add
                      <span>
                        <FaChevronCircleRight />
                      </span>
                    </button>
                  </>
                )}
              </h2>
            </div>
          </div>
        </Modal>
      )}
      {locationModal && (
        <Modal
          modalStyle="w-[50%] important"
          onClose={() => {
            setLocationEdit(null);
            setLocationVal("");
            setShowLocationModal(false);
          }}
        >
          <div className="flex w-full gap-5">
            <div className="w-full border-r-2 border-gray-400 pr-7 ">
              <h2 className="text-lg font-semibold">Location Filter</h2>
              <div className="flex flex-col gap-1 h-72  overflow-y-auto scroll-smooth mt-2">
                {locationsList &&
                  locationsList?.length > 0 &&
                  locationsList.map((l) => (
                    <LocationCard key={l?.c_l_id} l={l} setLocationEdit={setLocationEdit} setLocationVal={setLocationVal} handleGetLocations={handleGetLocations} />
                  ))}
              </div>
            </div>
            <div className="w-full">
              <h2 className="text-lg font-semibold">
                <label className="mr-4 block text-lg font-semibold" htmlFor="title">
                  Title
                </label>
                {locationEdit !== null ? (
                  <>
                    <input value={locationVal} onChange={(e) => setLocationVal(e.target.value)} className="w-full rounded mt-2 border h-12 px-4" type="text" />
                    <div className="flex gap-4">
                      <button onClick={handleEditLocation} className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center">
                        Edit
                        <span>
                          <FaChevronCircleRight />
                        </span>
                      </button>
                      <button
                        onClick={() => {
                          setLocationEdit(null);
                          setLocationVal("");
                        }}
                        className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center"
                      >
                        cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <input value={locationVal} onChange={(e) => setLocationVal(e.target.value)} className="w-full rounded mt-2 border h-12 px-4" type="text" />
                    <button onClick={handleCreateLocation} className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center">
                      Add
                      <span>
                        <FaChevronCircleRight />
                      </span>
                    </button>
                  </>
                )}
              </h2>
            </div>
          </div>
        </Modal>
      )}
      {workModal && (
        <Modal
          modalStyle="w-[50%] important"
          onClose={() => {
            setWorkEdit(null);
            setWorkVal("");
            setShowWorkModal(false);
          }}
        >
          <div className="flex w-full gap-5">
            <div className="w-full border-r-2 border-gray-400 pr-7 ">
              <h2 className="text-lg font-semibold">Work Filter</h2>
              <div className="flex flex-col gap-1 h-72  overflow-y-auto scroll-smooth mt-2">
                {workTypesList &&
                  workTypesList?.length > 0 &&
                  workTypesList.map((w) => <WorkCard key={w?.c_w_id} w={w} setWorkEdit={setWorkEdit} setWorkVal={setWorkVal} handleGetWorks={handleGetWorks} />)}
              </div>
            </div>
            <div className="w-full">
              <h2 className="text-lg font-semibold">
                <label className="mr-4 block text-lg font-semibold" htmlFor="title">
                  Title
                </label>
                {workEdit !== null ? (
                  <>
                    <input value={workVal} onChange={(e) => setWorkVal(e.target.value)} className="w-full rounded mt-2 border h-12 px-4" type="text" />
                    <div className="flex gap-4">
                      <button onClick={handleEditWork} className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center">
                        Edit
                        <span>
                          <FaChevronCircleRight />
                        </span>
                      </button>
                      <button
                        onClick={() => {
                          setWorkEdit(null);
                          setWorkVal("");
                        }}
                        className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center"
                      >
                        cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <input value={workVal} onChange={(e) => setWorkVal(e.target.value)} className="w-full rounded mt-2 border h-12 px-4" type="text" />
                    <button onClick={handleCreateWork} className="bg-black text-white px-4 py-2 rounded-md mt-5 flex gap-2 items-center">
                      Add
                      <span>
                        <FaChevronCircleRight />
                      </span>
                    </button>
                  </>
                )}
              </h2>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default Jobs;
