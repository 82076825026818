import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const jobsApi = createApi({
  reducerPath: "jobsApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "http://localhost:3005/api/",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    // login api call
    createJob: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/create`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    editJob: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/update/${body?.car_id}`,
          method: "put",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    deleteJob: builder.mutation({
      query: (jobId) => {
        return {
          url: `/jobs/delete/${jobId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    getJobs: builder.mutation({
      query: () => {
        return {
          url: `/jobs/get`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    getJobData: builder.mutation({
      query: (jobId) => {
        return {
          url: `/jobs/${jobId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    listWorkTypes: builder.mutation({
      query: () => {
        return {
          url: `/jobs/listWorkTypes`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    createWork: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/createWork`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        return response.data;
      },
    }),
    editWork: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/updateWork/${body?.c_w_id}`,
          method: "put",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    deleteWork: builder.mutation({
      query: (workId) => {
        return {
          url: `/jobs/deleteWork/${workId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    listTeams: builder.mutation({
      query: () => {
        return {
          url: `/jobs/listTeams`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    createTeam: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/createTeam`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    editTeam: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/updateTeam/${body?.c_t_id}`,
          method: "put",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    deleteTeam: builder.mutation({
      query: (teamId) => {
        return {
          url: `/jobs/deleteTeam/${teamId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    listLocations: builder.mutation({
      query: () => {
        return {
          url: `/jobs/listLocations`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    createLocation: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/createLocation`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        return response.data;
      },
    }),
    editLocation: builder.mutation({
      query: (body) => {
        return {
          url: `/jobs/updateLocation/${body?.c_l_id}`,
          method: "put",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        return response.data;
      },
    }),
    deleteLocation: builder.mutation({
      query: (locationId) => {
        return {
          url: `/jobs/deleteLocation/${locationId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetJobsMutation,
  useGetJobDataMutation,
  useListWorkTypesMutation,
  useCreateWorkMutation,
  useEditWorkMutation,
  useDeleteWorkMutation,
  useListLocationsMutation,
  useCreateLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
  useListTeamsMutation,
  useCreateTeamMutation,
  useEditTeamMutation,
  useDeleteTeamMutation,
  useDeleteJobMutation,
  useEditJobMutation,
  useCreateJobMutation,
} = jobsApi;
