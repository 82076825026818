import { Fragment, useEffect, useState } from "react";
import { useDeleteLocationMutation, useDeleteWorkMutation } from "../../store/services/jobs";

import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";

import { useToast } from "@chakra-ui/react";
import Modal from "../Modal";

const WorkCard = ({ w, setWorkEdit, setWorkVal, handleGetWorks }) => {
  const [deleteWork, { isSuccess: deleteWorkSuccess, isError: deleteWorkError }] = useDeleteWorkMutation();

  const toast = useToast();

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    if (deleteWorkSuccess) {
      toast({
        title: "WorkType Deleted Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      handleGetWorks();
      setDeleteModal(false);
    }
    if (deleteWorkError) {
      toast({
        title: "Error Deleting WorkType",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setDeleteModal(false);
    }
  }, [deleteWorkSuccess, deleteWorkError]);

  return (
    <>
      <div className="flex justify-between items-center bg-gray-200 px-2 py-1 ">
        <span>{w?.c_w_title}</span>
        <span className="flex gap-2 items-center">
          <span
            onClick={() => {
              setWorkVal(w?.c_w_title);
              setWorkEdit(w);
            }}
            className="cursor-pointer "
          >
            <MdEdit />
          </span>
          <span
            onClick={() => {
              setDeleteModal(true);
              setDeleteId(w?.c_w_id);
            }}
            className="cursor-pointer"
          >
            <MdDelete />
          </span>
        </span>
      </div>
      {deleteModal && (
        <Modal
          onClose={() => {
            setDeleteModal(false);
            setDeleteId(null);
          }}
        >
          <div>
            <h4 className="text-lg font-medium mb-3 text-center">Are you sure you want to delete?</h4>
            <div className="flex gap-4">
              <button onClick={() => deleteWork(deleteId)} className="bg-black text-white rounded-lg px-4 py-2 w-full">
                Yes
              </button>
              <button
                onClick={() => {
                  setDeleteModal(false);
                  setDeleteId(null);
                }}
                className="border border-black rounded-lg px-4 py-2 w-full"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default WorkCard;
