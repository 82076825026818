import { useAppDispatch } from "../store/hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/features/authSlice";

function Sidebar({ isAuthenticated }) {
  // toggling the dark mode state to toggle darkmode

  const navigate = useNavigate();

  const sideLinks = [
    {
      label: "Career Applications",
      link: "/career",
    },
    {
      label: "Blogs",
      link: "/Blogs",
    },
    {
      label: "Jobs",
      link: "/jobs",
    },
    {
      label: "HIVE US",
      link: "/usRegistrations",
    },
    {
      label: "Create User",
      link: "/CreateUser",
    },
  ];

  return (
    <nav className="bg-white  h-full fixed top-16 shadow-md   w-1/6    border-gray-200 ">
      <ul className="w-full ">
        {sideLinks.map((link) => {
          return (
            <li key={link.label} onClick={() => navigate(link.link)} className="w-full p-3 text-center border cursor-pointer hover:bg-slate-500 hover:text-white">
              {link.label}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Sidebar;
