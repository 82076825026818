import { useEffect, useState } from "react";
import { useGetRegistrationsMutation } from "../../store/services/hiveUS";
import CustomDropdown from "../../components/CustomSelect";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [getRegistrations, { data, isSuccess }] = useGetRegistrationsMutation();

  useEffect(() => {
    getRegistrations("");
  }, []);

  return (
    <main className="w-full bg-white flex-col flex p-10">
      <div className="filters w-full relative my-2">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Blogs</h1>
        </div>
      </div>
      <table className="border-collapse rounded-xl overflow-hidden">
        <tr className="bg-black divide-x text-white  border">
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>State</th>
          <th>City</th>
          <th>Going To Use HIVE At:</th>
        </tr>
        {data &&
          data.length > 0 &&
          data.map((val) => {
            return (
              <tr className="text-center divide-x">
                <td>{val.name}</td>
                <td>{val.email}</td>
                <td>{val.phone}</td>
                <td>{val.state}</td>
                <td>{val.city}</td>
                <td>{val.usingWhere}</td>
              </tr>
            );
          })}
      </table>
    </main>
  );
};

export default Blogs;
