import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authApi } from "./services/authApi";
import { hiveApi } from "./services/hiveApi";
import { blogsApi } from "./services/blogs";
import { careerApis } from "./services/applicants";
import { hiveUSApi } from "./services/hiveUS";
import { jobsApi } from "./services/jobs";
import authReducer from "./features/authSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { rtkQueryErrorLogger } from "../middlewares/error-handler";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

// First, combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  // [hiveApi.reducerPath]: hiveApi.reducer,
  [careerApis.reducerPath]: careerApis.reducer,
  [blogsApi.reducerPath]: blogsApi.reducer,
  [jobsApi.reducerPath]: jobsApi.reducer,
  [hiveUSApi.reducerPath]: hiveUSApi.reducer,
});

// Then, wrap this combined reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, careerApis.middleware, blogsApi.middleware, jobsApi.middleware, hiveUSApi.middleware, rtkQueryErrorLogger),
});

export const persistor = persistStore(store);
