import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const hiveUSApi = createApi({
  reducerPath: "hiveUSApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "http://localhost:3005/api/",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getRegistrations: builder.mutation({
      query: () => {
        return {
          url: `/hiveregistrations/`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        console.log("res", response);
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
  }),
});

export const { useGetRegistrationsMutation } = hiveUSApi;
